import _ from 'lodash';
import React from 'react';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
} from 'react-table';
import Select from 'react-select';
import PropTypes from 'prop-types';
import matchSorter from 'match-sorter';
import {
  TextField,
  Backdrop,
  CircularProgress,
  Slider,
  InputLabel,
  FormControl,
  MenuItem,
  IconButton,
  Select as SelectNative,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Typography,
  Tooltip,
  Paper,
  Grid,
  makeStyles,
} from '@material-ui/core';
import {
  Delete as ResetIcon,
  Refresh as RefreshIcon,
  AddCircleOutline as AddIcon,
  PersonOutlineOutlined as ProfileIcon,
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import fetchData from '../utilities/fetchData';
import EmployeeDialog from './EmployeeDialog';
import CreateRoleDialog from './CreateRoleDialog';
import gollum from '../images/gollum.png';

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;
const Axios = axios.create({ baseURL: BASE_URL });

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  errors: {
    textAlign: 'center',
    color: '#fff',
  },
  gollum: {
    margin: '2rem auto',
    display: 'block',
  },
}));

DefaultColumnFilter.propTypes = {
  column: PropTypes.object,
};

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <TextField
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

SelectColumnFilter.propTypes = {
  column: PropTypes.object,
};

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <SelectNative
      style={{ width: '100%' }}
      defaultValue="All"
      onChange={(e) => {
        const { value } = e.target;
        setFilter(value === 'All' ? undefined : value);
      }}
    >
      <MenuItem value="All">
        <em>All</em>
      </MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </SelectNative>
  );
}

NumberRangeColumnFilter.propTypes = {
  column: PropTypes.object,
};

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <Slider
      value={[
        _.toNumber(filterValue[0] || `${min}`),
        _.toNumber(filterValue[1] || `${max}`),
      ]}
      valueLabelDisplay="auto"
      onChange={(_event, value) => {
        setFilter(() => value);
      }}
      min={min}
      max={max}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

EmployeeTableImpl.propTypes = {
  onRefreshIconClick: PropTypes.func,
  onEmployeeClick: PropTypes.func,
  onRoleIconClick: PropTypes.func,
  columns: PropTypes.array,
  data: PropTypes.object,
};

// Our table component
function EmployeeTableImpl({
  onRefreshIconClick = _.noop,
  onEmployeeClick = _.noop,
  onRoleIconClick = _.noop,
  columns,
  data,
}) {
  const [skillFilter, setSkillFilter] = React.useState([]);
  const [rolesFilter, setRolesFilter] = React.useState([]);
  const [confidence, setConfidence] = React.useState(85);
  const [competency, setCompetency] = React.useState(0);

  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
      // just like "includes" but works for two arrays
      skillMatch: (rows, _id, filterValue) => {
        return rows.filter((row) => {
          const skills = row.original.skills
            .filter(({ skillName, experience }) => {
              // if this is a cert or filter does not care about it, pass through
              if (experience === 0 || competency === 0) return true;
              // "exact role" restriction on this skill
              if (competency === 4) {
                // find this skill's highest role experience required
                const roleExperience = _.max(
                  rolesFilter
                    // find the role object by its name
                    .map(({ value }) => _.find(data.roles, ['name', value]))
                    // find the skill in this role object
                    .map(({ skills }) =>
                      _.find(skills, ['skillName', skillName]),
                    )
                    // make sure skill exists in the role
                    .map((sk) => (sk === undefined ? { experience: -1 } : sk))
                    // extract their XP in this skill
                    .map(({ experience }) => experience),
                );
                // no role restriction on this skill
                if (roleExperience < 0) return true;
                else return roleExperience === experience;
              }
              // "minimum experience required" restriction
              return experience >= competency;
            })
            .map(({ skillName }) => skillName);
          const { length } = _.difference(filterValue, skills);
          const match =
            (Math.abs(length - filterValue.length) / filterValue.length) * 100;
          return match >= confidence;
        });
      },
    }),
    [competency, confidence, rolesFilter, data.roles],
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    setAllFilters,
    setFilter,
    // The rest of these things are super handy, too ;)
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data: data.employees,
      initialState: {
        hiddenColumns: ['Skills', 'costRate'],
        pageSize: 10,
      },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //useSortBy!
    usePagination, // usePagination!
  );

  const skills = _.get(_.find(state.filters, ['id', 'Skills']), 'value', []);
  const skillFilterFlat = skillFilter.map(({ value }) => value);
  const rolesFilterFlat = _.flatten(
    rolesFilter
      .map(({ value }) => _.find(data.roles, ['name', value]))
      .map((role) => {
        return _.flatten([
          role.certifications,
          role.skills.map(({ skillName }) => skillName),
        ]);
      }),
  );
  const skillsUnion = _.union(skillFilterFlat, rolesFilterFlat);
  if (!_.isEqual(skillsUnion, skills))
    setFilter('Skills', _.isEmpty(skillsUnion) ? undefined : skillsUnion);
  const getRenderSkills = () => {
    return skillsUnion.map((skill) => {
      return { value: skill, label: skill };
    });
  };
  const setRenderSkills = (entries) => {
    const entrySKills = entries.map(({ value }) => value);
    const entrySkillFilter = _.difference(entrySKills, rolesFilterFlat);
    setSkillFilter(
      entrySkillFilter.map((skill) => {
        return { value: skill, label: skill };
      }),
    );
  };

  const resetFilters = () => {
    setAllFilters([]);
    setSkillFilter([]);
    setRolesFilter([]);
    setGlobalFilter('');
  };

  return (
    <Table {...getTableProps()} size="small" style={{ marginTop: '1rem' }}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={visibleColumns.length}>
            <Grid container spacing={3}>
              <Grid item xs>
                <Tooltip title="Global fuzzy text filter, this filter is applied across all columns in the table. Use this for quick text searching.">
                  <TextField
                    label={`Text Filter (${preGlobalFilteredRows.length} records)`}
                    value={state.globalFilter}
                    style={{ width: '100%' }}
                    variant="outlined"
                    onChange={(e) => {
                      setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                    }}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs>
                <Tooltip
                  placement="left"
                  title="Employee competency filter. This filter applies to the roles you select. 'Skills Only' = you do not care about competency level. 'Role Exact' = you want what's in the role 1:1. Other options = you want an employee to at least meet role's skills with the selected competency level."
                >
                  <FormControl variant="outlined" style={{ width: '100%' }}>
                    <InputLabel id="competency-select">Competency</InputLabel>
                    <SelectNative
                      labelId="competency-select"
                      onChange={(event) => setCompetency(event.target.value)}
                      value={competency}
                      label="Competency"
                    >
                      <MenuItem value={0}>Skills Only</MenuItem>
                      <MenuItem value={1}>&gt;= Entry</MenuItem>
                      <MenuItem value={2}>&gt;= Junior</MenuItem>
                      <MenuItem value={3}>&gt;= Senior</MenuItem>
                      <MenuItem value={4}>Role Exact</MenuItem>
                    </SelectNative>
                  </FormControl>
                </Tooltip>
              </Grid>
              <Grid item xs>
                <InputLabel>Confidence: {confidence}</InputLabel>
                <Tooltip title="Employee confidence filter. The percentage an employee matches with your skills and roles selected. 100% = you want every single skill selected to exist in an employee's profile. 85% = it's OK for an employee to not have some of the skills you selected (15%).">
                  <Slider
                    onChange={(_target, value) => setConfidence(value)}
                    value={confidence}
                    max={100}
                    min={0}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs style={{ textAlign: 'right' }}>
                <Tooltip title="Refresh Data">
                  <IconButton
                    variant="contained"
                    color="primary"
                    style={{ margin: '0 0.5rem' }}
                    onClick={onRefreshIconClick}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Add A New Role">
                  <IconButton
                    variant="contained"
                    color="primary"
                    style={{ margin: '0 0.5rem' }}
                    onClick={onRoleIconClick}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Reset Filters">
                  <IconButton
                    variant="contained"
                    color="primary"
                    onClick={resetFilters}
                    style={{ margin: '0 0.5rem' }}
                  >
                    <ResetIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={visibleColumns.length}>
            <Grid container spacing={3}>
              <Grid item xs>
                <Select
                  onChange={(entries) => setRolesFilter(entries || [])}
                  value={rolesFilter}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={true}
                  styles={{
                    multiValue: (styles) => {
                      return {
                        ...styles,
                        backgroundColor: 'orange',
                      };
                    },
                  }}
                  placeholder="Select Role Filter(s)"
                  options={data.roles.map(({ name }) => {
                    return { value: name, label: name };
                  })}
                />
              </Grid>
              <Grid item xs>
                <Select
                  onChange={(entries) => setRenderSkills(entries || [])}
                  value={getRenderSkills()}
                  closeMenuOnSelect={false}
                  isSearchable={true}
                  isClearable={true}
                  isMulti={true}
                  styles={{
                    multiValue: (styles, { data }) => {
                      return {
                        ...styles,
                        ...(rolesFilterFlat.includes(data.value)
                          ? { backgroundColor: 'orange' }
                          : {}),
                      };
                    },
                    multiValueRemove: (styles, { data }) => ({
                      ...styles,
                      ...(rolesFilterFlat.includes(data.value)
                        ? { display: 'none' }
                        : {}),
                    }),
                  }}
                  placeholder="Select Skill Filter(s)"
                  options={data.skills.map(({ skillName }) => {
                    return { value: skillName, label: skillName };
                  })}
                />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
        {headerGroups.map((headerGroup, i) => (
          <TableRow key={`header-${i}`} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell key={column.Header} {...column.getHeaderProps()}>
                <span {...column.getSortByToggleProps()}>
                  {column.render('Header')}
                </span>
                <span>
                  {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                </span>
                {/* Render the columns filter UI */}
                <div>{column.canFilter ? column.render('Filter') : null}</div>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <TableRow key={`employee-${i}`} {...row.getRowProps()}>
              {row.cells.map((cell, colNum) => {
                return (
                  <TableCell
                    key={`employee-${i}-${colNum}`}
                    {...cell.getCellProps()}
                  >
                    <Typography variant="body1">
                      {colNum ? null : (
                        <IconButton
                          style={{ marginRight: '0.5rem' }}
                          onClick={() => onEmployeeClick(row.original)}
                        >
                          <ProfileIcon color="action" />
                        </IconButton>
                      )}
                      {cell.render('Cell')}
                    </Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
      <TableFooter style={{ margin: '5rem' }}>
        <TableRow>
          <TablePagination
            count={data.employees.length}
            page={state.pageIndex}
            onChangePage={(_event, page) => gotoPage(page)}
            onChangeRowsPerPage={({ target }) => setPageSize(target.value)}
            rowsPerPageOptions={_.uniq(
              _.range(10, data.employees.length, 5).concat([
                data.employees.length,
              ]),
            )}
            rowsPerPage={state.pageSize}
            color="primary"
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
}

EmployeeTable.propTypes = {
  apiKey: PropTypes.string,
};

function EmployeeTable({ apiKey }) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Employee Name',
        columns: [
          {
            Header: 'First Name',
            accessor: 'firstName',
            filter: 'fuzzyText',
          },
          {
            Header: 'Last Name',
            accessor: 'lastName',
            filter: 'fuzzyText',
          },
        ],
      },
      {
        Header: 'Employee Detail',
        columns: [
          {
            Header: 'Title',
            accessor: 'title',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Rate',
            accessor: 'costRate',
            Filter: NumberRangeColumnFilter,
            filter: 'between',
          },
          {
            Header: 'Client',
            accessor: 'currentEngagement',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Travel',
            accessor: 'travelAppetite',
            Filter: SelectColumnFilter,
            filter: 'includes',
          },
          {
            Header: 'Skills',
            filter: 'skillMatch',
          },
        ],
      },
    ],
    [],
  );

  const [data, setData] = React.useState({});
  const [refreshToken, setRefreshToken] = React.useState(0);
  const fetchDataMemo = React.useMemo(() => {
    console.log(`refreshing data attempt: ${refreshToken}`);
    setData({});
    return fetchData(apiKey);
  }, [refreshToken, apiKey]);

  const addRole = (newRole) => {
    newRole.skills = _.get(newRole, 'skills', []).map((skill) => {
      return {
        name: skill.skillName,
        experience: skill.experience,
      };
    });
    Axios.put('roles', newRole, { headers: { 'x-api-key': apiKey } })
      .then(() => {
        setRefreshToken(refreshToken + 1);
        enqueueSnackbar(
          `Role "${newRole.name}" saved successfully! Refreshing data.`,
          {
            variant: 'success',
            preventDuplicate: true,
          },
        );
      })
      .catch(() =>
        enqueueSnackbar(`Role "${newRole.name}" failed to save!`, {
          variant: 'error',
          preventDuplicate: true,
        }),
      );
  };

  const [fetchFailed, setFetchFailed] = React.useState(false);
  const [roleDialogOpen, setRoleDialogOpen] = React.useState(false);
  const [employeeSelected, setEmployeeSelected] = React.useState({});
  const [employeeDialogOpen, setEmployeeDialogOpen] = React.useState(false);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  fetchDataMemo
    .then((data) => {
      setFetchFailed(false);
      enqueueSnackbar('data pulled successfully!', {
        preventDuplicate: true,
        variant: 'success',
      });
      setData(data);
    })
    .catch((err) => {
      console.error(err);
      setFetchFailed(true);
      enqueueSnackbar('failed to pull data!', {
        preventDuplicate: true,
        variant: 'error',
      });
    });

  return fetchFailed ? (
    <Grid
      container
      spacing={0}
      justify="center"
      direction="column"
      alignItems="center"
      style={{ minHeight: 500 }}
    >
      <Grid item xs={7}>
        <Typography className={classes.errors} variant="h4">
          We were unable to find the precious data :(
        </Typography>
        <img src={gollum} width="150" className={classes.gollum} alt=":(" />
        <Typography className={classes.errors}>
          Check your Internet connection or try again later. Check your
          browser&apos;s console. If you notice any red error logs, notify the
          developers in the <em>#internal-staffing-planner</em> Slack channel so
          they can assist you.
        </Typography>
      </Grid>
    </Grid>
  ) : _.isEmpty(data) ? (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <TableContainer component={Paper}>
      <EmployeeDialog
        open={employeeDialogOpen}
        employee={employeeSelected}
        onClose={() => setEmployeeDialogOpen(false)}
      />
      <CreateRoleDialog
        open={roleDialogOpen}
        onNewRole={addRole}
        onClose={() => setRoleDialogOpen(false)}
        certs={data.certNames}
        skills={data.skillNames}
      />
      <EmployeeTableImpl
        columns={columns}
        data={data}
        onRoleIconClick={() => setRoleDialogOpen(true)}
        onEmployeeClick={(employee) => {
          setEmployeeSelected(employee);
          setEmployeeDialogOpen(true);
        }}
        onRefreshIconClick={() => {
          setRefreshToken(refreshToken + 1);
        }}
      />
    </TableContainer>
  );
}

export { EmployeeTable };
export default EmployeeTable;
