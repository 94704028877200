import React from 'react';
import {
  Grid,
  AppBar,
  Tooltip,
  Toolbar,
  Typography,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { StelligentLogin } from '@stelligent/universal-google-sso';
import { ExitToApp as LogOutIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import { EmployeeTable } from './components';
import gandalf from './images/no.png';
import './App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  loginTitle: {
    textAlign: 'center',
    margin: '2rem auto',
    color: '#fff',
  },
}));

function App() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [apiKey, setApiKey] = React.useState(false);

  const signOut = () => {
    if (window.gapi) {
      const auth2 = window.gapi.auth2.getAuthInstance();
      if (auth2 != null) {
        auth2.signOut().then(
          auth2.disconnect().then(() => {
            window.location.reload(false);
            setApiKey('');
          }),
        );
      }
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Stelligent Staffing Planner
          </Typography>
          <Tooltip title="Log Out Of The Staffing Planner">
            <IconButton
              onClick={signOut}
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <LogOutIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      {apiKey ? (
        <EmployeeTable apiKey={apiKey} />
      ) : (
        <Grid
          container
          spacing={0}
          justify="center"
          direction="column"
          alignItems="center"
          style={{ minHeight: 500 }}
        >
          <Grid item xs={7}>
            <img
              src={gandalf}
              alt="You Shall Not Pass!"
              width="250"
              height="auto"
              style={{ display: 'block', margin: '0 auto' }}
            />
          </Grid>
          <Grid item xs={7}>
            <StelligentLogin
              onApiKey={(key) => {
                setApiKey(key);
                if (!key) {
                  enqueueSnackbar(
                    'Authentication failed! Did you login with an @stelligent.com account?',
                    {
                      preventDuplicate: true,
                      variant: 'error',
                    },
                  );
                } else {
                  enqueueSnackbar('Authenticated successfully!', {
                    preventDuplicate: true,
                    variant: 'success',
                  });
                }
              }}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}

export default App;
