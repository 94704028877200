import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Form from '@rjsf/material-ui';
import { Button } from '@material-ui/core';

const createSchema = (certs, skills) => {
  return {
    type: 'object',
    title: 'Create a new Role',
    required: ['name'],
    properties: {
      name: {
        type: 'string',
        title: 'Role Name',
        description:
          'A human friendly name to identify this role with (e.g. Senior DevOps Engineer).',
      },
      certifications: {
        type: 'array',
        title: 'Certifications',
        default: [],
        description: 'List of certifications attributed to this role.',
        items: {
          type: 'string',
          enum: certs,
        },
      },
      skills: {
        type: 'array',
        title: 'Skills',
        default: [],
        description:
          'List of skills and their experience level attributed to this role.',
        items: {
          type: 'object',
          required: ['skillName', 'experience'],
          properties: {
            skillName: {
              type: 'string',
              title: 'Skill Name',
              enum: skills,
            },
            experience: {
              type: 'string',
              title: 'Competency Level',
              enum: ['Entry', 'Junior', 'Senior'],
            },
          },
        },
      },
    },
  };
};

CreateRoleForm.propTypes = {
  onNewRole: PropTypes.func.isRequired,
  skills: PropTypes.array,
  certs: PropTypes.array,
};

const CompetencyMap = {
  Entry: 1,
  Junior: 2,
  Senior: 3,
};

function CreateRoleForm(props) {
  const { onNewRole = _.noop, certs = [], skills = [] } = props;

  const schema = React.useMemo(() => createSchema(certs, skills), [
    certs,
    skills,
  ]);

  return (
    <Form
      schema={schema}
      liveValidate={true}
      onSubmit={({ formData }) => {
        formData.skills = formData.skills.map((skill) => {
          return { ...skill, experience: CompetencyMap[skill.experience] };
        });
        onNewRole(formData);
      }}
    >
      <Button type="submit" color="primary" variant="contained">
        Save Role
      </Button>
    </Form>
  );
}

export { CreateRoleForm };
export default CreateRoleForm;
