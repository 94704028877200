import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Container } from '@material-ui/core';

import CreateRoleForm from './CreateRoleForm';

function CreateRoleDialog(props) {
  const {
    onNewRole = _.noop,
    onClose = _.noop,
    open = false,
    certs = [],
    skills = [],
  } = props;

  return (
    <Dialog onClose={onClose} open={open}>
      <Container style={{ padding: '2rem' }}>
        <CreateRoleForm onNewRole={onNewRole} certs={certs} skills={skills} />
      </Container>
    </Dialog>
  );
}

CreateRoleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onNewRole: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  skills: PropTypes.array,
  certs: PropTypes.array,
};

export { CreateRoleDialog };
export default CreateRoleDialog;
