import _ from 'lodash';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BACKEND_ENDPOINT;
const Axios = axios.create({ baseURL: BASE_URL });

const TRAVEL_MAP = ['Hermit', 'Seldom', 'Periodic', 'Road Warrior'];

const SKILLS_KEYMAP = {
  id: 'id',
  category: 'category',
  skillname: 'skillName',
  certification: 'certification',
};

const EMPLOYEES_KEYMAP = {
  id: 'id',
  first_name: 'firstName',
  last_name: 'lastName',
  email: 'email',
  title: 'title',
  cost_rate: 'costRate',
  current_engagement: 'currentEngagement',
  travel_appetite: 'travelAppetite',
  scheduled_pto: 'scheduledPTO',
  skills: 'skills',
};

const fetchSkills = async (apiKey) => {
  const { data: skillsResponse } = await Axios.get('skills/dump', {
    headers: { 'x-api-key': apiKey },
  });
  const skills = _.get(skillsResponse, 'skills', [])
    .map((skill) => _.mapKeys(skill, (_v, K) => SKILLS_KEYMAP[K]))
    .map((skill) => _.defaults(skill, { certification: false }));
  const skillsByName = _.keyBy(skills, (skill) => skill.skillName);
  return { skills, skillsByName };
};

const fetchEmployees = async (apiKey, skillsByName) => {
  const { data: employeesResponse } = await Axios.get('employees/dump', {
    headers: { 'x-api-key': apiKey },
  });
  return _.get(employeesResponse, 'employees', [])
    .filter((employee) => employee.first_name && employee.last_name)
    .map((employee) => _.mapKeys(employee, (_v, k) => EMPLOYEES_KEYMAP[k]))
    .map((employee) =>
      _.defaults(employee, {
        currentEngagement: 'bench',
        skills: [],
        travelAppetite: 0,
      }),
    )
    .map((employee) => {
      return {
        ...employee,
        currentEngagement: employee.currentEngagement.toLowerCase(),
        travelAppetite: TRAVEL_MAP[employee.travelAppetite],
        skills: employee.skills.map((skill) => {
          return {
            experience: _.get(skill, 'experience', 1),
            ...skillsByName[skill.skillname],
          };
        }),
      };
    });
};

const fetchRoles = async (apiKey, skillsByName) => {
  const { data: rolesResponse } = await Axios.get('roles', {
    headers: { 'x-api-key': apiKey },
  });
  const roles = [];
  const getSkillName = (input) =>
    _.isObject(input) ? _.get(input, 'name', 'no data') : input;
  await Promise.all(
    _.get(rolesResponse, 'roles', []).map(async (role) => {
      const { data: roleResponse } = await Axios.get(`roles/${role}`, {
        headers: { 'x-api-key': apiKey },
      });
      roles.push({
        name: _.get(roleResponse, 'name', 'no data'),
        skills: _.filter(
          roleResponse.skills.map(
            (nameOrSkill) => skillsByName[getSkillName(nameOrSkill)],
          ),
        ),
        certifications: _.filter(
          roleResponse.certifications.map(
            (nameOrSkill) => skillsByName[getSkillName(nameOrSkill)],
          ),
        ).map(({ skillName }) => skillName),
      });
    }),
  );
  return roles;
};

export default async function fetchData(apiKey) {
  const { skills, skillsByName } = await fetchSkills(apiKey);
  const [employees, roles] = await Promise.all([
    fetchEmployees(apiKey, skillsByName),
    fetchRoles(apiKey, skillsByName),
  ]);
  const skillNames = skills
    .filter(({ certification }) => !certification)
    .map(({ skillName }) => skillName)
    .sort();
  const certNames = skills
    .filter(({ certification }) => certification)
    .map(({ skillName }) => skillName)
    .sort();
  return { skills, employees, roles, skillNames, certNames };
}
