import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  CardActionArea,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  list: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 200,
  },
});

const CompetencyMap = ['Certification', 'Entry', 'Junior', 'Senior'];

function EmployeeDialog(props) {
  const { employee = {}, onClose = _.noop, open = false } = props;

  const classes = useStyles();

  return _.isEmpty(employee) ? null : (
    <Dialog fullWidth={true} maxWidth="xs" onClose={onClose} open={open}>
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {`${employee.firstName} ${employee.lastName} (${employee.title})`}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Email Address:{' '}
            <b>
              <a href={`mailto:${employee.email}`}>{employee.email}</a>
            </b>
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Cost Rate: <b>{employee.costRate}</b>
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Currently On: <b>{employee.currentEngagement}</b>
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Travel Appetite: <b>{employee.travelAppetite}</b>
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Skills:
          </Typography>
        </CardContent>
      </CardActionArea>
      <List className={classes.list}>
        {employee.skills.map((skill, i) => {
          return (
            <ListItem key={i}>
              <ListItemText>{`${skill.skillName} (${
                CompetencyMap[skill.experience]
              })`}</ListItemText>
            </ListItem>
          );
        })}
      </List>
    </Dialog>
  );
}

EmployeeDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  employee: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

export { EmployeeDialog };
export default EmployeeDialog;
